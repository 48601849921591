import type { CardItemItem } from "@/utils";
import { Reflex } from "@chat/component";

type DigitProps = {
    data: CardItemItem[]
}

function Digit(props: DigitProps) {
    const { data } = props;
    return <div className="dice-game">
        {
            data.map(item => {
                return <div className="dice-game-item">
                    <Reflex.QuickRace type={ item.number }></Reflex.QuickRace>
                    {/*<div className="price">₹{ item.amount.toFixed(2) } </div>*/ }
                </div>;
            })
        }

    </div>;
}

export default Digit;
