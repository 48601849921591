import { CustomType, ZIMCustomMessage } from "@chat/zim-sdk";
import gameIcon from "@/assets/svg/gameIcon.svg";
import cls from "./lasetMessageStyle.module.scss";

type PicProps = {
    message: ZIMCustomMessage
};
const getText = (subType: any) => {
    switch (subType) {
        case CustomType.Game:
            return "Bet Slip";
        case CustomType.PredictiveEvent:
            return "Predictive Event";
        default:
            return "Customer Card";
    }
};
export default function Custom(props: PicProps) {
    const customData = {
        image: gameIcon,
        text: getText(props.message.subType),
    };
    return <div className={ cls.container }>
        <img src={ customData.image } className="text-inner-image" alt=""/>
        {
            customData.text
        }
    </div>;
}
