import React from 'react';
import './chat.scss';
import NoticeBar from "@/pages/Chat/feature/NoticeBar";
import WinHistory from "@/pages/Chat/feature/WinHistory";
import ChatMain from "@/pages/Chat/feature/ChatMain";
import ChatFooter from "@/pages/Chat/feature/InputFoot";
import SelectNavbar from "@/pages/Chat/feature/SelectNavbar";
import FollowedModal from "@/pages/Chat/feature/Modal/FollowedModal";
import NoticeDetailModal from "@/pages/Chat/feature/Modal/NoticeDetailModal";
import RulesModal from "@/pages/Chat/feature/Modal/RulesModal";
import SettingModal from "@/pages/Chat/feature/Modal/SettingModal";
import PersonalModal from "@/pages/Chat/feature/Modal/PersonalModal";
import TwiceModal from "@/components/TwiceModal";
import useInit from "@/pages/Chat/feature/hooks/useInit";
import classNames from "classnames";
import VideoModal from "@/pages/Chat/feature/Modal/VideoModal";
import RechargeModal from "@/pages/Chat/feature/Modal/RechargeModal";
import BetModal from "@/pages/Chat/feature/Modal/PredictionModal/BetModal";
import HistoryModal from "@/pages/Chat/feature/Modal/PredictionModal/HistoryModal";
const Chat = () => {
    const { chatMode } = useInit();
    return (
        <div className={ classNames("chat-container") }>
            <SelectNavbar/>
            <NoticeBar/>
            <WinHistory/>
            <ChatMain/>
            {
                chatMode === "inside" ? null : <ChatFooter/>
            }
            <FollowedModal/>
            <NoticeDetailModal/>
            <RulesModal/>
            <SettingModal/>
            <PersonalModal/>
            <TwiceModal/>
            <VideoModal/>
            <RechargeModal/>
            <BetModal/>
            <HistoryModal/>
        </div>
    );
};

export default Chat;
