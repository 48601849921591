import React, { useCallback, useEffect, useState } from "react";
import { ConversationType, ZIMMessage } from "@chat/zim-sdk";
import {
    MessageStatus,
    MessageReceiptStatus,
} from "@chat/zim-sdk";
import classnames from "classnames";
import {
    ReadStatus,
    Sending
} from "@/components/themeSvg";
import { getMmaTime, getVmSize } from "@chat/shared";
import { SUBSCRIBERS_COUNT } from "@/utils";
import { useAppSelector } from "@/store";
import { useParams } from "react-router-dom";

type MessageStateProps = {
    data: ZIMMessage;
    style?: React.CSSProperties;
    className?: string
}
function MessageState(props: MessageStateProps) {
    const { data, className, style } = props;
    const { groupInfo } = useAppSelector(state => state.chat);
    const { conversationType = ConversationType.Unknown } = useParams();
    const [count, setCount] = useState(0);
    const getCount = useCallback(() => {
        if (+conversationType !== ConversationType.Room) return 0;
        const time = data.timestamp;
        const now = Date.now();
        const offset = Math.max(now - time, 0);
        // 大于2小时，那么全部已读。否则，1分钟 + 10
        let second = Math.floor(offset / 1000 / 4);
        const count = Math.min((groupInfo?.subscribers || 0) + SUBSCRIBERS_COUNT, second + Math.floor(Math.random() * 400));
        return count;
    }, [data, conversationType, groupInfo]);
    useEffect(() => {
        if (+conversationType === ConversationType.Room) {
            setCount(getCount());
        }
    }, [conversationType, groupInfo]);
    return <div
        className={ classnames("message-state", +conversationType === ConversationType.Room && groupInfo?.groupType === 3 && "message-group-state", className) }
        style={ style }>
        <span>{ getMmaTime(data.timestamp) } </span>
        {
            data.sentStatus === MessageStatus.Sending && <Sending className="message-icon-sending"/>
        }
        {
            data.sentStatus === MessageStatus.Success && <div className="message-status-success">
                <ReadStatus read={ data.receiptStatus === MessageReceiptStatus.Done } className="message-icon-read"/>
                {
                    +conversationType === ConversationType.Room && groupInfo?.groupType === 3 &&
                    <span style={ { marginRight: getVmSize(2) } }>{ count }Seen</span>
                }
            </div>
        }


    </div>;
}

export default MessageState;
