import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { homeSlice } from "./slice/home";
import { chatSlice } from "./slice/chat";
import { commonSlice } from "@/store/slice/common";
import { groupSetting } from "@/store/slice/groupSetting";
import { personalSlice } from "@/store/slice/personal";
import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";
import logger from "redux-logger";
import { followed } from "@/store/slice/followed";
import { userSlice } from "@/store/slice/user";
import { gifSlice } from "@/store/slice/git";
import {predictionSlice} from "@/store/slice/prediction";

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
const store = configureStore({
    reducer: {
        home: homeSlice.reducer,
        chat: chatSlice.reducer,
        common: commonSlice.reducer,
        groupSetting: groupSetting.reducer,
        followed: followed.reducer,
        personal: personalSlice.reducer,
        user: userSlice.reducer,
        gif: gifSlice.reducer,
        prediction: predictionSlice.reducer
    },
    middleware(getDefaultMiddleware) {
        let middleware = getDefaultMiddleware();
        const customWare = [];
        if (process.env.NODE_ENV === "development") {
            customWare.push(logger);
        }
        return middleware.concat(customWare);
    }
});
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
