import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/store";
import { addConversationList, fetchConversationList, setTotalUnread, updateConversationList } from "@/store/slice/home";
import type { ConversationListener } from "@chat/zim-sdk";
import {
    addConversationListener,
    removeConversationListener,
} from "@chat/zim-sdk";
// import { setConversationDetail } from "@/store/slice/chat";
import { getVisibleUnReadCount, isVisitorUser, sendUnreadChange, visitorStore } from "@/utils";
import { useLatest } from "ahooks";

function useHomeInit() {
    const dispatch = useAppDispatch();
    const { list } = useAppSelector(state => state.home);
    const latestList = useLatest(list);
    useEffect(() => {
        dispatch(fetchConversationList());
        const onConversationChanged: ConversationListener["onConversationChanged"] = (infoList) => {
            infoList.forEach(item => {
                const { conversation } = item;
                const exist = latestList.current.find(item => item.conversationID === conversation.conversationID);
                if (exist) {
                    dispatch(updateConversationList(conversation));
                } else {
                    dispatch(addConversationList(conversation));
                }
                // 如果是匿名用户，升级一下数据
                if (visitorStore.isPermanentUser(conversation.conversationID) && isVisitorUser()) {
                    if (item.conversation.lastMessage) {
                        const oldList = visitorStore.getHistoryList(conversation.conversationID);
                        const curOldMsgIndex = oldList.findIndex(oldmsg => oldmsg.messageID === item.conversation.lastMessage!.messageID)
                        if (curOldMsgIndex !== -1){
                            // 更新
                            oldList[curOldMsgIndex] = item.conversation.lastMessage
                        }else {
                            oldList.push(item.conversation.lastMessage);
                        }
                        visitorStore.setHistoryList(conversation.conversationID, oldList as any)
                    }
                }
            });
            const unreadCount = getVisibleUnReadCount();
            sendUnreadChange(unreadCount);
        };
        const onTotalUnreadMessageCountChanged: ConversationListener["onTotalUnreadMessageCountChanged"] = (totalUnreadMessageCount: number) => {
            dispatch(setTotalUnread(totalUnreadMessageCount));
        };
        addConversationListener({
            onConversationChanged,
            onTotalUnreadMessageCountChanged
        });
        return () => {
            removeConversationListener();
        };
    }, []);
}

export default useHomeInit;
