import { useAppSelector } from "@/store";
import { useEffect, useState } from "react";

export default function useIdentity(userID: string, imCustomer = ["1", "2"]) {
    const [isIdentity, setIsIdentity] = useState(false);
    const { identityList } = useAppSelector(state => state.common);
    useEffect(() => {
        const bol = identityList.some(item => item.imUserId === userID && imCustomer.includes(item.imCustomer));
        setIsIdentity(bol);
    }, [userID, identityList]);
    return {
        isIdentity
    };
}
