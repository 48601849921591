import { MessageType, ZIMAudioMessage, ZIMCustomMessage, ZIMMessage, ZIMVideoMessage } from "@chat/zim-sdk";
import { Fragment } from "react";
import Text from "./Text";
import Pic from "./Pic";
import Audio from "@/pages/Home/features/LastMessage/Audio";
import Video from "@/pages/Home/features/LastMessage/Video";
import Custom from "./Custom";

type LastMessageProps = {
    message: ZIMMessage;
};

// customCard.svg
function LastMessage(props: LastMessageProps) {
    const message: ZIMMessage = props.message || {}
    switch (message.type) {
        case MessageType.Text:
            return <Text message={ message }></Text>;
        case MessageType.Audio:
            return <Audio message={ message as ZIMAudioMessage }></Audio>;
        case MessageType.Video:
            return <Video message={ message as ZIMVideoMessage }></Video>;
        case MessageType.Image:
            return <Pic message={ message }></Pic>;
        case MessageType.Custom:
            return <Custom message={ message as ZIMCustomMessage }></Custom>;
        default:
            return <Fragment>receive a new message</Fragment>;
    }
}

export default LastMessage;
