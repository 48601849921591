import FloatModal from "@/components/FloatModal";
import { useAppDispatch, useAppSelector } from "@/store";
import { closeHistory } from "@/store/slice/prediction";
import cls from "./style.module.scss";
import { Image } from "antd-mobile";
import pic from "@/assets/game-pic/scratch off.png";
import { Reflex, DiceType } from "@chat/component";

function HistoryModal() {
    const dispatch = useAppDispatch();
    const { historyVisible } = useAppSelector(state => state.prediction);

    const handlerRender = () => {
        return <div className="followedModal-handler">
            <div className="followed-button" style={ { width: "100%" } }>Confirm</div>
        </div>;
    };
    return <FloatModal visible={ historyVisible } onClose={ () => {
        dispatch(closeHistory());
    } } handler={ handlerRender() }>
        <div className={ cls.container }>
            <div className={ cls.detail }>
                <Image src={ pic } className={ cls.pic }/>
                <div className={ cls.detail__info }>
                    <div className={ cls.name }>Dice-5 miute</div>
                    <div className={ cls.id }>20240711010765</div>
                </div>
                <div className={ cls.detail__time }>
                    <div className={ cls.drawText }>Draw time</div>
                    <div className={ cls.drawTime }>29-12-2024 8:00:34</div>
                </div>
            </div>
            <div className={ cls.line }></div>
            <div className={ cls.analyze }>
                <div className={ cls.analyze_item }>
                    <div className={ cls.analyze_item_label }>Prediction Count</div>
                    <div className={ cls.analyze_item_value }>1223</div>
                </div>
                <div className={ cls.analyze_item }>
                    <div className={ cls.analyze_item_label }>Hit Count</div>
                    <div className={ cls.analyze_item_value } style={ { textAlign: "center" } }>1111</div>
                </div>
                <div className={ cls.analyze_item }>
                    <div className={ cls.analyze_item_label }>Accuracy Rate</div>
                    <div className={ cls.analyze_item_value } style={ { textAlign: "end" } }>90%</div>
                </div>
            </div>
        </div>
        <table className={ cls.table }>
            <thead>
            <tr>
                <td className={ cls.issue }>ISSUE</td>
                <td className={ cls.cell }>
                    <div>FORECAST</div>
                    <div>RESULTS</div>
                </td>
                <td className={ cls.cell }>
                    <div>WINNING</div>
                    <div>RESULTS</div>
                </td>
                <td className={ cls.status }>STATUS</td>
            </tr>
            </thead>
            <tbody>

            <tr>
                <td className={ cls.issue }>
                    <div className={ cls.cell }>20240711</div>
                </td>
                <td>
                    <div style={ { display: "flex", justifyContent: "center" } }>
                        <Reflex.Dice type={ DiceType.SIGNLE } value={ "1" }></Reflex.Dice>
                        <Reflex.Dice type={ DiceType.SIGNLE } value={ "1" }></Reflex.Dice>
                        <Reflex.Dice type={ DiceType.SIGNLE } value={ "1" }></Reflex.Dice>
                    </div>
                </td>
                <td>
                    <div style={ { display: "flex", justifyContent: "center" } }>
                        <Reflex.Dice type={ DiceType.SIGNLE } value={ "1" }></Reflex.Dice>
                        <Reflex.Dice type={ DiceType.SIGNLE } value={ "1" }></Reflex.Dice>
                        <Reflex.Dice type={ DiceType.SIGNLE } value={ "1" }></Reflex.Dice>
                    </div>
                </td>
                <td className={ cls.status }>
                    <div className={ cls.hit }>HIT</div>
                    <div className={ cls.missed }>Missed</div>
                </td>
            </tr>
            </tbody>

        </table>
    </FloatModal>;
}

export default HistoryModal;
