import React from 'react';
import ReactDOM from 'react-dom/client';
// import VConsole from 'vconsole';
import 'normalize.css/normalize.css';
import 'antd-mobile/bundle/css-vars-patch.css'; // 兼容ios9
import "@/assets/fonts/font.css";
import "@chat/component/dist/component.css"
import App from './App';
import reportWebVitals from './reportWebVitals';
import { setup as SDKSetup } from "@chat/zim-sdk";
// import VConsole from "vconsole";

let ZIM_APP_ID_DEV = 601884274; // 国外 开发
let ZIM_APP_ID_PROD = 859135233; // 国外 生产
// let ZIM_APP_ID = 775388; // 国内
const isDev = process.env.ENV_FILE === "test";
if (isDev) {
    SDKSetup(ZIM_APP_ID_DEV);
} else {
    SDKSetup(ZIM_APP_ID_PROD);
}
// if (process.env.NODE_ENV === "development") {
//     new VConsole();
// }
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <App/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
