import { forwardRef, HtmlHTMLAttributes, ReactNode, useImperativeHandle, useRef, useState } from "react";
import cls from "./floating-panel.module.scss";
import { useDrag } from "@use-gesture/react";
import { useSpring, animated } from "@react-spring/web";
import classNames from "classnames";
import { useMemoizedFn } from "ahooks";

export type FloatingPanelProps = {
    children?: ReactNode;
    customHeader?: ReactNode;
    fixed?: boolean;
    anchors: number[];
    onHeightChange?: (height: number, isAnimating: boolean) => void;
    handleDraggingOfContent?: boolean;
} & HtmlHTMLAttributes<any>
export type FloatingPanelRef = {
    setHeight: (height: number, options?: { immediate?: boolean }) => void
}
const nearest = (arr: number[], target: number) => {
    return arr.reduce((prev, curr) => {
        return Math.abs(prev - target) < Math.abs(curr - target) ? prev : curr;
    });
};
export const FloatingPanel = forwardRef<FloatingPanelRef, FloatingPanelProps>((props, ref) => {
    const { children, anchors, className } = props;
    const possibles = anchors.map(x => -x);
    const elementRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const [pulling, setPulling] = useState(false);
    const pullingRef = useRef(false);

    const bounds = {
        top: possibles[possibles.length - 1],
        bottom: possibles[0]
    };

    const onHeightChange = useMemoizedFn(props.onHeightChange ?? (() => {
    }));
    const [{ y }, api] = useSpring(() => ({
        y: bounds.bottom,
        config: { tension: 300 }, onChange: (result) => {
            onHeightChange(-result.value.y, y.isAnimating);
        }
    }));
    const maxHeight = y.to(y => -Math.round(y));
    useDrag((state) => {
            const offsetY = state.offset[1]
            if (state.first) {
                // 开始触摸屏幕
                const target = state.event.target as Element;
                const header = headerRef.current;
                if (header === target || header?.contains(target)) {
                    pullingRef.current = true;
                } else {
                    if (!props.handleDraggingOfContent) return;
                    const reachedTop = y.goal <= bounds.top;
                    const content = contentRef.current;
                    if (!content) return;
                    if (reachedTop) {
                        if (content.scrollTop <= 0 && state.direction[1] > 0) {
                            pullingRef.current = true;
                        }
                    } else {
                        pullingRef.current = true;
                    }
                }
            }
            setPulling(pullingRef.current);
            if (!pullingRef.current) return;
            const { event } = state;
            if (event.cancelable) {
                event.preventDefault();
            }
            event.stopPropagation();
            let nextY = offsetY;
            // 释放手指
            if (state.last) {
                pullingRef.current = false;
                setPulling(false);
                nextY = nearest(possibles, offsetY);
            }
            api.start({
                y: nextY
            });
        },
        {
            axis: "y",
            target: elementRef,
            rubberband: true,
            from: () => [0, y.get()],
            pointer: { touch: true },
            eventOptions: { passive: false }
        }
    );
    useImperativeHandle(ref, () => ({
        setHeight: (height, options) => {
            api.start({
                y: -height,
                immediate: options?.immediate
            });
        }
    }), [api]);
    return <animated.div ref={ elementRef } style={ {
        height: maxHeight,
        position: props.fixed ? "fixed" : "static",
        translateY: y.to(y => `calc(100% + (${ Math.round(y) }px))`)
    } } className={ classNames(cls.container, className) }>
        <div className={ cls.mask } style={ { display: pulling ? "block" : "none" } }></div>
        <div className={ cls.header } ref={ headerRef }>
            <div className={ cls.header__bar }></div>
        </div>
        {
            props.customHeader && <div className={ cls.header__content }>{ props.customHeader }</div>
        }
        <div className={ cls.content } ref={ contentRef }>
            {
                children
            }
        </div>
    </animated.div>;
});
