import SettingModal from "@/components/SettingModal";
import { useAppDispatch, useAppSelector } from "@/store";
import { deleteMessageFormStore, deleteMessageModalHidden, setSelectState } from "@/store/slice/chat";
import { toast } from "@/utils";
import { ConversationType, GroupMemberRole, deleteMessages as deleteSDKMessage } from "@chat/zim-sdk";
import { useCustomMessage } from "@/hooks/useCustomMessage";
import { useParams } from "react-router-dom";

function DeleteMessageModal() {
    const dispatch = useAppDispatch();
    const { conversationID, conversationType = ConversationType.Unknown } = useParams();
    const { sendDeleteFlag } = useCustomMessage();

    const isSingle = +conversationType === ConversationType.Peer;
    const { self } = useAppSelector(state => state.user);
    const { selectList, deleteMessageModalVisible, selfInfo } = useAppSelector(state => state.chat);
    const closebar = () => {
        dispatch(setSelectState(false));
        dispatch(deleteMessageModalHidden());
    };
    const showDeleteEveryone = () => {
        // 1. 判断是不是自己的信息，如果是的话，可以删除所有人
        // 2. 如果不是自己的信息，
        // 首先判断所有的消息
        const hasOther = selectList.find(item => item.senderUserID !== self.userID);
        if (hasOther) {
            // 存在其他人的信息
            // 判断是不是单聊
            if (isSingle) {
                return false;
            }
            // 判断自己是不是管理员，如果是的话，可以删除所有人
            // ！没写
            return selfInfo.memberRole === GroupMemberRole.Admin;
        } else {
            return true;
        }
    };
    // all 为真 删除全部 all为假 删除本地
    const deleteMessage = async (all: boolean) => {
        try {
            if (+conversationType === ConversationType.Room) {
                // 发送清除命令
                const res = await sendDeleteFlag(selectList, conversationID!, true);
                if (res.code !== 0) {
                    toast(res.msg, "error");
                    return;
                }
            } else {
                await deleteSDKMessage(selectList, conversationID!, all);
            }
            dispatch(deleteMessageFormStore(selectList));
            // 提示完成
            toast("delete success!", "success");
            // 关闭弹框
            closebar();
        } catch (err: any) {
            dispatch(deleteMessageModalHidden());
            toast(err, "error");
        }
    };
    return <SettingModal
        title="Friendly reminder"
        visible={ deleteMessageModalVisible }
        onMaskClick={ () => dispatch(deleteMessageModalHidden()) }
        confirm={ () => deleteMessage(false) }
        cancel={ showDeleteEveryone() ? isSingle ? () => deleteMessage(true) : void 0 : void 0 }
        cancelText="Delete for everyone"
        confirmText={ isSingle ? "Delete for me" : "Delete for everyone" }
    >
        <div>Delete { selectList.length } messsage?</div>
    </SettingModal>;
}

export default DeleteMessageModal;
