import cls from "./foot.module.scss";
import React, { ChangeEvent, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/store";
import voiceRedIcon from "@/assets/svg/voice-red.svg";
import {
    closeFace,
    openFace,
    setChatMessage,
    // setInputMenusVisible,
    setQuoteMessage, setRechargeVisible,
} from "@/store/slice/chat";
import { hasEmoji } from "@chat/shared";
import Quote from "@/pages/Chat/feature/InputFoot/Quote";
import { motion, PanInfo } from "framer-motion";
import useRecorder from "@/pages/Chat/feature/hooks/useRecorder";
import classnames from "classnames";
import { BackT4, Face, InputPicture, InputRecharge, KeyBoard, SendBaw, VoiceBaw } from "@/components/themeSvg";
import { Space } from "antd-mobile";
import useFileMessage from "@/pages/Chat/feature/hooks/useFileMessage";
import useSendMessage from "@/pages/Chat/feature/hooks/useSendMessage";
import rechargeIcon from "@/assets/svg/recharge.svg";
import { ConversationType, createAudioMessage } from "@chat/zim-sdk";
import { useParams } from "react-router-dom";
import useIdentity from "@/hooks/useIdentity";
import { isVisitorUser, sendFocusChanged } from "@/utils";

const BaseHeight = 20;
const maxHeight = BaseHeight * 11;

type InputBottomProps = {
    handleSend: (...args: any[]) => any;
    showMenus?: boolean;
}

function InputBottom(props: InputBottomProps, ref: any) {
    // useWatchKeyBoard();
    const { conversationID = "", conversationType = ConversationType.Unknown } = useParams();
    const { createImageOrVideoMessage } = useFileMessage();
    const { isIdentity } = useIdentity(conversationID, ["1"]);
    const { sendMessage } = useSendMessage();
    const { handleSend, showMenus } = props;
    const touchActive = useRef(false);
    const [textareaHeight, setTextareaHeight] = useState(BaseHeight);
    const { getMedia, stopRecord, normalizeTime, duration, mediaAuth, startRecord } = useRecorder();
    const screen = document.querySelector(".layout")?.clientWidth || window.innerWidth;
    const maxDragX = screen / 2 - 50;
    const dispatch = useAppDispatch();
    const {
        chatMessage,
        showFace,
        quoteMessage,
        // conversationDetail
        // inputMenusVisible
    } = useAppSelector(state => state.chat);
    const LeftIcon = showFace ? KeyBoard : Face;
    const inputRef = useRef<HTMLInputElement>(null);
    const listenFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files![0];
        e.target.value = "";
        e.target.files = null;
        createImageOrVideoMessage(file).then(message => {
            // 发送消息
            sendMessage({
                message,
                conversationID: conversationID
            });
        });
    };
    const renderButton = () => {
        // 群聊关闭语音，单聊开启
        if (+conversationType === ConversationType.Room) return sendButton();
        return chatMessage.trim().length > 0 ? sendButton() : voiceButton();
    };
    const [tap, setTap] = useState(false);
    const [offsetX, setOffsetX] = useState(0);
    const handleDrag = (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
        let offset = info.offset.x;
        if (offset > 0) {
            offset = 0;
        } else {
            offset = Math.min(Math.abs(offset), maxDragX);
        }
        setOffsetX(offset);
    };


    const voiceButton = () => {
        // 发送语音
        const sendVoiceMessage = async (file: File, duration: number) => {
            const message = createAudioMessage(file, duration);
            await sendMessage({
                message,
                conversationID: conversationID
            });
            // 清空引用
            dispatch(setQuoteMessage(null));
        };
        // 手指按下
        const onTapStart = async () => {
            touchActive.current = true;
            if (mediaAuth.current) {
                setTap(true);
                startRecord();
            } else {
                const stream = await getMedia();
                // 这个时候还没获取到权限
                if (stream && touchActive.current) {
                    setTap(true);
                    startRecord();
                }
            }
        };
        // 释放手指
        const handleTapEnd = () => {
            // 判断当前是否有权限，是否开启计时
            // 重置状态
            setOffsetX(0);
            setTap(false);
            touchActive.current = false;
            if (!duration) return;
            if (duration && duration < 50) {
                stopRecord();
                return;
            }
            if (!mediaAuth.current) return;
            if (offsetX === maxDragX) {
                // 取消发送
                // toast("cancel");
                stopRecord();
            } else {
                // 发送出去
                stopRecord().then(res => {
                    setTimeout(() => {
                        sendVoiceMessage(res.file!, Math.ceil(res.duration / 1000));
                    }, 10);
                });
            }
        };
        return <motion.div
            drag="x"
            whileTap={ { scale: 2 } }
            className={ cls.chatBtn }
            dragSnapToOrigin={ true }
            onTapStart={ onTapStart }
            onTap={ handleTapEnd }
            onTapCancel={ handleTapEnd }
            onDrag={ handleDrag }
            dragElastic={ 0 }
            dragConstraints={ { right: 0, left: -maxDragX } }
            // onClick={ handleVoice }
        >
            <VoiceBaw className={ cls.chatBtn__icon }/>
        </motion.div>;
    };
    const sendButton = () => {
        return <div className={ cls.chatBtn }>
            <SendBaw className={ cls.chatBtn__icon } onClick={ handleSend }/>
        </div>;
    };
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const changeChatMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        dispatch(setChatMessage(e.target.value));
    };
    const handleFace = () => {
        if (showFace) {
            dispatch(closeFace());
        } else {
            dispatch(openFace());
        }
    };
    // 获取当前的光标位置， 返回区间
    const getCursorPosition = () => {
        const textArea = textareaRef.current;
        if (textArea) {
            const start = textArea.selectionStart;
            const end = textArea.selectionEnd;
            if (start === 0 && end === 0) {
                return [chatMessage.length, chatMessage.length];
            }
            return [textArea.selectionStart, textArea.selectionEnd];
        }
        return [0, 0];
    };
    // 删除单个
    const deleteChangeMessage = () => {
        let [start] = getCursorPosition();
        const textArea = textareaRef.current;
        if (!textArea) return;
        if (chatMessage.length === 1) {
            dispatch(setChatMessage(""));
            return;
        }
        let prev = chatMessage.substring(0, start);
        const next = chatMessage.substring(start);
        let num = 1;
        if (prev.length <= 1) {
            prev = "";
        } else {
            num = hasEmoji(prev.substring(prev.length - 2, prev.length)) ? 2 : 1;
            prev = prev.substring(0, prev.length - num);
        }
        setTimeout(() => {
            // textArea.focus();
            textArea.setSelectionRange(start - num, start - num);
        });
        dispatch(setChatMessage(prev + next));
    };
    useImperativeHandle(ref, () => {
        return {
            deleteChangeMessage
        };
    }, [chatMessage]);
    useEffect(() => {
        const dom = textareaRef.current;
        if (dom) {
            dom.style.height = BaseHeight + "px";
            const scrollHeight = dom.scrollHeight;
            const height = Math.min(maxHeight, scrollHeight);
            setTextareaHeight(height);
            dom.style.height = `${ height }px`;
        }
    }, [chatMessage]);
    const renderVoiceBox = () => {
        return <>
            <div className={ cls.voiceBox }>
                <img className={ cls.voiceIcon } src={ voiceRedIcon } alt=""/>
                <div className={ cls.shadow }></div>
                <div className={ cls.voiceTime }>{ normalizeTime.join(":") }</div>
                <div className={ cls.cancel }>
                    <BackT4 className={ cls.cancelIcon }/>
                    <span>Slide to cancel</span>
                </div>
            </div>
            <div style={ { width: offsetX + "px" } }></div>
        </>;
    };
    // const handleMenus = () => {
    //     dispatch(setInputMenusVisible(!inputMenusVisible));
    // };
    const focusTimer = useRef<NodeJS.Timeout>();
    const renderChatBox = () => {
        return <div
            className={ classnames(cls.messageBox, { [cls.messageBox__hasValue]: textareaHeight > BaseHeight || !!quoteMessage }) }>
            <Quote/>
            <div className={ cls.messageBox__inner }>
                <LeftIcon className={ cls.chatIcon } onClick={ handleFace }/>
                <div className={ cls.textAreaContainer }>
                    <textarea
                        onFocus={ () => {
                            // 做个延迟等软键盘跳出，发送一个消息给到平台
                            // 解决留白问题
                            focusTimer.current && clearTimeout(focusTimer.current);
                            focusTimer.current = setTimeout(() => {
                                sendFocusChanged(true);
                            }, 100);
                            dispatch(closeFace());
                        } }
                        ref={ textareaRef }
                        value={ chatMessage }
                        onBlur={ () => {
                            focusTimer.current && clearTimeout(focusTimer.current);
                            sendFocusChanged(false);
                        } }
                        // disabled={ isVisitorUser() }
                        onChange={ changeChatMessage }
                        className={ cls.textArea }
                        placeholder={ "Message" }
                    />
                </div>
                {
                    showMenus && <Space className={ cls.rightMenus }>
                        {/*<InputClip className={ cls.chatIcon } onClick={ handleMenus }/>*/ }
                        {
                            isIdentity && !isVisitorUser() &&
                            <InputRecharge src={ rechargeIcon } className={ cls.chatIcon }
                                           onClick={ () => dispatch(setRechargeVisible(true)) }/>
                        }
                        <InputPicture className={ cls.chatIcon } onClick={ () => {
                            inputRef.current?.click();
                        } }/>
                    </Space>
                }
                <input style={ { display: "none" } }
                       onChange={ listenFileChange }
                       type="file"
                       ref={ inputRef }
                       accept="video/*,image/*"
                />
            </div>


        </div>;
    };
    return <>
        <div className={ cls.inputBottom }>
            {
                tap ? renderVoiceBox() : renderChatBox()
            }
            {
                renderButton()
            }
        </div>
    </>;
}

export default React.forwardRef(InputBottom);
