import React, { useEffect, useMemo, useRef, useState } from "react";
// import { DotLoading } from "antd-mobile";
import {
    ChatMessageItem,
    SystemMessageTypes,
    getMessageList,
    getConversationType,
    ConversationType,
    decorationMessage,
    ZIMCustomMessage,
    normalizeHistoryMessage
} from "@chat/zim-sdk";
import ChatItem from "@/pages/Chat/feature/ChatItem";
import { emitter } from "@/utils";
import { useDebounceFn } from "ahooks";
import SystemNotification from "@/pages/Chat/feature/SystemNotification";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "@/store";
import useMarkRead from "@/pages/Chat/feature/hooks/useMarkRead";
import { AnimatePresence, motion } from "framer-motion";
import { ToBottom } from "@/components/themeSvg";
import { useParams } from "react-router-dom";
import { addHistoryMessage } from "@/store/slice/chat";
import { getRoomMsg } from "@/apis/room";
import store2 from "store2";

// import { DotLoading } from "antd-mobile";

function ChatMain() {
    const listRender = useRef(1);
    const [markRead] = useMarkRead();
    const dispatch = useAppDispatch();
    const { notReadNum, list, loading } = useAppSelector(state => state.chat);
    const { conversationID = "" } = useParams();
    // const theme = useAppSelector(state => state.common.theme);
    const [bottomBtn, setBottomBtn] = useState(false);
    const handleToBottom = (config?: { behavior?: "smooth" | "auto", index?: number }) => {
        requestAnimationFrame(() => {
            const { behavior = "auto" } = config || {};
            const container = document.querySelector("#virtual") as HTMLDivElement;
            if (container) {
                const lastChild = container.lastElementChild as HTMLElement;
                if (lastChild) {
                    lastChild.scrollIntoView({
                        behavior,
                        block: "end",
                        inline: "nearest"
                    });
                }
                markRead();
            }
        });
    };
    // 监听到数据更新
    const addMessage = () => {
        if (!bottomBtn) {
            handleToBottom({ behavior: 'smooth' });
        }
    };
    const virtualRef = useRef<HTMLDivElement>(null);

    const remindMessageNum = useMemo(() => {
        return notReadNum > 99 ? "99+" : notReadNum;
    }, [notReadNum]);
    const rowRenderer = (rows: ChatMessageItem[]) => {
        return rows.map(data => {
            if (SystemMessageTypes.includes(data.type as any)) {
                return <SystemNotification data={ data } key={ data.messageID }/>;
            }
            return <ChatItem data={ data } key={ data.messageID }></ChatItem>;
        });

    };
    // 导航栏滚动到最底部显示按钮
    const { run: handleScroll } = useDebounceFn((scroll = true) => {
        const target = document.querySelector("#virtual") as HTMLDivElement;
        const limitValue = 20;
        if (!target) return;
        const scrolltop = target.scrollTop;
        const offsetHeight = target.offsetHeight;
        const scrollHeight = target.scrollHeight;
        if (scrolltop <= 20 && scroll) {
            loadMore(scrollHeight, scrolltop);
        }
        if (scrolltop + offsetHeight + limitValue < scrollHeight) {
            if (bottomBtn) return;
            setBottomBtn(true);
        } else {
            if (!bottomBtn) return;
            // 标记已读
            if (notReadNum) {
                markRead();
            }
            setBottomBtn(false);
        }
    }, { wait: 200 });
    useEffect(() => {
        emitter.on("CHAT_LIST_SCROLL_TO_BOTTOM", handleToBottom);
        emitter.on("CHAT_LIST_SCROLL_BY_SHOW_BTN", addMessage);
        return () => {
            emitter.off("CHAT_LIST_SCROLL_TO_BOTTOM", handleToBottom);
            emitter.off("CHAT_LIST_SCROLL_BY_SHOW_BTN", addMessage);
        };
    }, [addMessage]);
    const isFetch = useRef(false);
    const paginationConfig = useRef({
        pageNum: 2,
        pageSize: 20,
        end: false
    });
    const handlerAddScroll = (oldScrollTop: number, oldScrollHeight: number, container: HTMLDivElement, messageID: string) => {
        const target = document.querySelector(`div[data-key="${ messageID }"]`);
        requestAnimationFrame(() => {
            container.scrollTop = oldScrollTop + (container.scrollHeight - oldScrollHeight);

            target?.scrollIntoView({
                behavior: "smooth"
            });
            isFetch.current = false;
        });
    };
    const loadMore = (scrollHeight: number, scrolltop: number) => {
        if (listRender.current === 0) {
            listRender.current++;
            return;
        }

        if (getConversationType(conversationID) === ConversationType.Peer) {
            loadMorePeer(scrollHeight, scrolltop);
        }
        if (getConversationType(conversationID) === ConversationType.Room) {
            loadMoreRoom(scrollHeight, scrolltop);
        }
    };
    const loadMorePeer = (scrollHeight: number, scrollTop: number) => {
        // 根据聊天场景，选择适合的方法
        if (isFetch.current) {
            return;
        }
        isFetch.current = true;
        const firstMessage = list.find(item => item.senderUserID && item.messageSeq);
        getMessageList(conversationID, { nextMessage: firstMessage, count: 20 }).then(res => {
            if (!res.length) {
                isFetch.current = false;
                paginationConfig.current.end = true;
                return;
            }
            dispatch(addHistoryMessage(res));
            const messageID = res[res.length - 1]?.messageID;
            if (messageID) {
                const container = document.querySelector("#virtual") as HTMLDivElement;
                // 恢复滚动位置
                handlerAddScroll(container.scrollTop, container.scrollHeight, container, messageID);
            }
        }).catch(err => {
            console.error(err);
            isFetch.current = false;
            paginationConfig.current.end = true;
        });
    };
    const loadMoreRoom = (scrollHeight: number, scrollTop: number) => {
        // 根据聊天场景，选择适合的方法
        if (isFetch.current) {
            return;
        }
        const config = paginationConfig.current;
        if (config.end) return;
        isFetch.current = true;
        getRoomMsg({
            groupId: conversationID,
            imUserId: store2.get("userID"),
            pageNum: config.pageNum,
            pageSize: config.pageSize
        }).then(res => {
            if (res.data.length < config.pageSize) {
                paginationConfig.current = {
                    ...paginationConfig.current,
                    end: true
                };
            } else {
                paginationConfig.current.pageNum++;
            }
            const historyList = res.data.map(normalizeHistoryMessage);
            const newList = decorationMessage(historyList as ZIMCustomMessage[]);
            dispatch(addHistoryMessage(newList));
            const messageID = newList[newList.length - 1]?.messageID;
            if (messageID) {
                const container = document.querySelector("#virtual") as HTMLDivElement;
                // 恢复滚动位置
                handlerAddScroll(container.scrollTop, container.scrollHeight, container, messageID);
            }
        }).catch((err) => {
            isFetch.current = false;
        });
    };
    useEffect(() => {
        handleScroll(false);
    }, [list]);
    useEffect(() => {
        if (!loading) {
            setTimeout(() => {
                handleToBottom({ behavior: "auto" });
            }, 50);
        }
    }, [loading]);
    useEffect(() => {
        isFetch.current = false;
        paginationConfig.current = {
            pageSize: 20,
            pageNum: 2,
            end: false
        };
    }, [conversationID]);
    return <div className="chat-main-container">
        <div className={ classNames("chat-main-inner") }>
            {
                loading ? <div className="loading">
                    {/*<DotLoading color={ theme.value["--whats-base-color"] }/>*/ }
                    {/*<span>Loading</span>*/ }
                </div> : <div id="virtual"
                              ref={ virtualRef }
                              style={ { height: "100%", overflow: "auto" } }
                              onScrollCapture={ () => handleScroll(true) }
                >
                    {
                        rowRenderer(list)
                    }
                </div>
            }

            <AnimatePresence>
                {
                    bottomBtn && <motion.div exit={ { opacity: 0, y: -20 } }
                                             initial={ { opacity: 0, y: 20 } }
                                             animate={ { opacity: 1, y: 0 } }
                                             onClick={ () => handleToBottom() }>
                        <ToBottom className="newMessageCount">
                            { remindMessageNum }
                        </ToBottom>
                    </motion.div>
                }

            </AnimatePresence>
        </div>


    </div>;
}

export default ChatMain;
