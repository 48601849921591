import {Reflex} from "@chat/component"
import type { CardItemItem } from "@/utils";

type SattaProps = {
    data: CardItemItem[]
}

function Satta(props: SattaProps) {
    const { data } = props;
    return <div className="dice-game">
        {
            data.map((item, index) => {
                return <div className="dice-game-item" key={index}>
                    <Reflex.Satta value={item.digits!} type={ item.number }/>
                    {/*<div className="price">₹{ item.amount.toFixed(2) } </div>*/}
                </div>;
            })
        }

    </div>;
}

export default Satta;
