import lottie, { AnimationItem } from "lottie-web";
import { useEffect, useRef } from "react";

type LottieProps = {
    className?: string;
    data: any;
    loop: boolean | number
};
const lottieMap = new WeakMap<HTMLDivElement, AnimationItem>();

const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            const lottie = lottieMap.get(entry.target as HTMLDivElement);
            if (lottie) {
                lottie.stop();
                setTimeout(() => {
                    lottie.play();
                })
            }
        }
    });
}, {
    root: null,
    threshold: 0.4
});

// 1.动起来，到了视口动两下
function Lottie(props: LottieProps) {
    const { className, loop, data } = props;
    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (containerRef.current) {
            const container = containerRef.current;
            const ani = lottie.loadAnimation({
                container,
                renderer: "svg",
                loop,
                autoplay: true,
                animationData: data
            });
            if (!lottieMap.has(container)) {
                lottieMap.set(container, ani);
            } else {
                const prevAni = lottieMap.get(container);
                prevAni?.destroy();
                lottieMap.delete(container);
                lottieMap.set(container, ani);
            }
            observer.observe(container);
        }
    }, []);
    return <div ref={ containerRef } className={ className }></div>;
}

export default Lottie;
