import cls from "./foot.module.scss";
import { useMemo } from "react";
import { useCountDown } from "@chat/shared";
import { setSelfMuted } from "@/store/slice/chat";
import { useAppDispatch, useAppSelector } from "@/store";


function MutedBottom({ always }: { always?: boolean }) {
    const { selfInfo } = useAppSelector(state => state.chat);
    const dispatch = useAppDispatch();
    const countDownTime = useCountDown(selfInfo.muteExpiredTime, () => {
        dispatch(setSelfMuted(0));
    });
    const remainingText = useMemo(() => {
        const { days, seconds, minutes, hours } = countDownTime;
        let text = "";
        if (days > 0) {
            text += countDownTime.days;
        }
        text += [hours, minutes, seconds].join(":");
        return text;
    }, [countDownTime]);
    const renderRemain = () => {
        if (always) return "All members are muted";
        return <> You are muted Remaining:
            <span>{ remainingText }</span></>;
    };

    return <div className={ cls.register }>
        {
            renderRemain()
        }
    </div>;
}

export default MutedBottom;
