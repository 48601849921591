import React from "react";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}


export default function VideoPlay(props: IconProps) {
    const { ...attrs } = props;
    return <span { ...attrs } role="img">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33" fill="none">
        <path d="M10.5 16.0986V8.01562L17.5 12.0571L24.5 16.0986L17.5 20.14L10.5 24.1815V16.0986Z" fill="white"
              stroke="white" strokeWidth="2.66667" strokeLinejoin="round"/>
    </svg>
    </span>;
}
