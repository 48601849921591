import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/store";
import {
    addNewMessage,
    deleteMessageFormStore,
    fetchHistoryList,
    fetchRechargeData,
    resetChat,
    setChatMessage,
    setConversationDetail,
    setGroupGameList,
    setHistoryVisible,
    setMessageList,
    setSelfInfo,
    setSelfMuted,
    updateGroupInfo,
    updateMessageReceipt,
    updateSingleInfo
} from "@/store/slice/chat";
import { showDismissVisible } from "@/store/slice/common";
import ImageViewer from "@/components/ImageViewer";
import store2 from "store2";
import { useNavigate, useParams } from "react-router-dom";
import {
    ConversationType,
    enterConversation as enterSDKConversation,
    leaveConversation as leaveSDKConversation,
    enterRoom as enterSDKRoom,
    decorationMessage,
    ZIMCustomMessage,
    MessageReceiptStatus,
    MessageType
} from "@chat/zim-sdk";
import { getUserInfo, leaveRoom, RoomEvent, getGroupUserInfo, normalizeHistoryMessage } from "@chat/zim-sdk";
import { emitter, isVisitorUser, toast, visitorStore } from "@/utils";
import { reset } from "@/store/slice/personal";
import { getAdminList, getUserGroupInfo, groupUserLastMsg } from "@/apis/room";
import { setAdminList, reset as groupReset } from "@/store/slice/groupSetting";
import { useAppSearchParams, useChatMode } from "@chat/shared";
import { resetGif } from "@/store/slice/git";
import { useLatest } from "ahooks";

function useInit() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { conversationType = "", conversationID = "" } = useParams();
    const { list } = useAppSelector(state => state.chat);
    const latestList = useLatest(list);
    const { logged } = useAppSelector(state => state.user);
    const amount = useAppSearchParams("amount");
    // normal正常的群聊 inside内嵌在游戏内部
    const { chatMode } = useChatMode();
    // 加入房间，退出房间都调用一次，用来标记未读和订阅
    const signRoom = useCallback((groupId: string, imUserId: string, lastMsgId: string) => {
        // 如果是游客不调用
        if (isVisitorUser()) {
            return;
        }
        groupUserLastMsg({
            groupId,
            imUserId,
            msgId: lastMsgId || null
        }).then(res => {
            console.log(res, "lastMsg");
        });
    }, []);

    // 获取当前群的管理列表
    const getMemberList = async () => {
        if (+conversationType !== ConversationType.Room) {
            return;
        }
        try {
            const res = await getAdminList({ groupId: conversationID });
            const adminList: string[] = res.data.map((item: any) => item.imUserId);
            dispatch(setAdminList(adminList));

        } catch (err) {
            console.log(err);
        }
    };
    const enterConversation = (userID: string) => {
        // 更新个人详情
        if (+conversationType === ConversationType.Peer) {
            getUserInfo(conversationID, true).then(res => {
                dispatch(updateSingleInfo(res.user));
                enterPeerOrGroupRoom(conversationID);
            });
            return () => {
                if (visitorStore.isPermanentUser(conversationID)) {
                    visitorStore.leaveConversation(conversationID, latestList.current);
                }
                leaveSDKConversation(conversationID);
            };
        }
        // 在线聊天室
        if (+conversationType === ConversationType.Room) {
            // 获取房间信息
            getUserGroupInfo({ groupId: conversationID, imUserId: userID }).then(res => {
                const { data, code } = res;
                if (code === 0) {
                    if (!data.imGroupVo.groupId) {
                        // 群被解散
                        dispatch(showDismissVisible());
                        return;
                    }
                    const user = getGroupUserInfo(data, store2.get("userID"));
                    // todo 公开房或者已经输入密码，那么加入房间
                    const canJoin = () => {
                        if (user.block) {
                            toast("You have been blocked", "error");
                            navigate("/home", { replace: true });
                            return false;
                        }
                        // 频道或者非密码房
                        if ([1, 3].includes(data.imGroupVo.groupType) || data.fillPass) {
                            return true;
                        }
                        return false;
                    };
                    if (canJoin()) {
                        const { groupId, groupName } = data.imGroupVo;
                        // 加入房间
                        enterRoom({ roomName: groupName, roomID: groupId }, userID).then(res => {
                            console.log("room enter success！", res);
                            // dispatch(fetchHistoryList(conversationID));
                        });
                        // 获取管理员列表
                        getMemberList();
                        // 获取用户数据
                        dispatch(setSelfInfo(user));
                        // 更新群信息，
                        dispatch(updateGroupInfo(data.imGroupVo));
                        dispatch(setGroupGameList(data.imUserGameNoticeVos));
                        // dispatch(fetchHistoryList(conversationID));
                        // 更新历史消息
                        if (data.lastMsgList) {
                            const historyList = data.lastMsgList.map(normalizeHistoryMessage);
                            dispatch(setMessageList(decorationMessage(historyList as ZIMCustomMessage[], conversationID)));
                            signRoom(groupId, userID, historyList[historyList.length - 1]?.messageID);
                            setTimeout(() => {
                                emitter.emit("CHAT_LIST_SCROLL_TO_BOTTOM", { behavior: "auto" });
                            }, 50);
                        }

                    } else {
                        console.log("需要获取密码");
                    }
                } else {
                    toast(res.msg || res.message, "error");
                }
            });
            if (chatMode === "normal") {
                // 普通模式开启历史
                dispatch(setHistoryVisible(true));
            }
            return () => {
                signRoom(conversationID, userID, latestList.current[latestList.current.length - 1]?.messageID);
                leaveRoom(conversationID);
            };
        }
    };
    // 根据id来判断需要加入的聊天房间
    const enterPeerOrGroupRoom = (conversationID: string) => {
        // 更新chat的聊天历史信息
        enterSDKConversation(conversationID, {
            onMessages: (list) => {
                // 更新信息
                list.forEach(message => {
                    dispatch(addNewMessage(message));
                });
                emitter.emit("CHAT_LIST_SCROLL_BY_SHOW_BTN");
            },
            onMessageReceiptChanged(infos) {
                infos.forEach(item => {
                    dispatch(updateMessageReceipt(item));
                });
            }
        }).then(({ conversation }) => {
            // // 更新会话详情
            dispatch(setConversationDetail(conversation));
            // 更新历史消息，如果是匿名用户，需要更新内容
            dispatch(fetchHistoryList(conversationID));

        }).catch(err => {
            if (visitorStore.isPermanentUser(conversationID)) {
                // 常驻客服加入聊天室
                visitorStore.entryConversation(conversationID);
                const history = visitorStore.getHistoryList(conversationID);
                history.filter((item: any) => item.type !== MessageType.TimeTip).forEach(message => {
                    dispatch(addNewMessage(message));
                });
            }
            console.error(err, `当前${ conversationID }会话暂未开启，输入信息后开启`);
        });
    };
    const enterRoom = async (roomInfo: {
        roomID: string;
        roomName: string
    }, userID: string) => {

        return enterSDKRoom(roomInfo, userID, {
            onMessages: (list) => {
                // 更新信息
                list.forEach(message => {
                    const newMessage = {
                        ...message,
                        receiptStatus: MessageReceiptStatus.Done
                    };
                    dispatch(addNewMessage(newMessage));
                });
                emitter.emit("CHAT_LIST_SCROLL_BY_SHOW_BTN");
                // 需要对数据处理，可能是自定义的系统消息，这个不需要添加到应用中
            },
            onMutedMessage: (data) => {
                if (data.muteExpiredTime > Date.now()) {
                    toast("You have been muted.", "error");
                } else {
                    toast("You can speak now.");
                }
                dispatch(setSelfMuted(data.muteExpiredTime));
            },
            onMessageDelete: data => {
                dispatch(deleteMessageFormStore(data.deleteMessageList));
            },
            onBlockMessage: () => {
                toast("You have been blocked by the administrator", "error");
                navigate("/home", { replace: true });
            },
            onRoomStateChanged: data => {
                // 房间状态变更
                // 需要通过类型去判断
                switch (data.event) {
                    case RoomEvent.RoomNotExist:
                        console.log("room不存在");
                        toast("Room does not exist", "error");
                        dispatch(resetChat());
                        navigate("/home", { replace: true });
                        break;
                    case RoomEvent.Success:
                        console.log("room加入成功");
                        break;
                    case RoomEvent.KickedOut:
                        console.log("room-user KickedOut!");
                        toast("You have been kicked out of this room.", "error");
                        dispatch(resetChat());
                        navigate("/home", { replace: true });
                        break;
                    case RoomEvent.KickedOutByOtherDevice:
                        console.log("KickedOutByOtherDevice");
                        dispatch(resetChat());
                        toast("Logged out due to multiple device logins", "error");
                        navigate("/home", { replace: true });
                        break;
                }
            },
            onRoomInfoChanged() {
                // 房间信息变更
                getUserGroupInfo({ groupId: roomInfo.roomID, imUserId: userID }).then(res => {
                    if (res.code === 0) {
                        dispatch(updateGroupInfo(res.data.imGroupVo));
                    }
                });
            },
            onRoomDismiss() {
                leaveRoom(roomInfo.roomID);
                dispatch(showDismissVisible());
            }
        });
    };
    useEffect(() => {
        if (+conversationType === ConversationType.Peer && !isVisitorUser()) {
            dispatch(fetchRechargeData());
        }
        if (+conversationType === ConversationType.Peer && +amount) {
            dispatch(setChatMessage(`I want to recharge ${ amount }`));
        }
        const leaveConversation = enterConversation(store2.get("userID"));
        return () => {
            ImageViewer.clear(); // 清除已有的图片预览
            // 退出聊天窗口，需要重置数据
            dispatch(resetChat());
            dispatch(reset());
            dispatch(resetGif());
            dispatch(groupReset());
            leaveConversation?.();
        };
    }, [conversationID, conversationType, logged, amount]);
    return {
        chatMode
    };
}

export default useInit;
