import FloatModal from "@/components/FloatModal";
import { useAppDispatch, useAppSelector } from "@/store";
import { closeForecast } from "@/store/slice/prediction";
import React, { useEffect, useState } from "react";
import { formatPrice } from "@chat/shared";
import cls from "./style.module.scss";
import { Button, Image } from "antd-mobile";
import { Reflex, DiceType, renderForecastResults } from "@chat/component";
import CheckedBox from "@/components/CheckBox";
import subIcon from "@/assets/svg/sub.svg";
import addIcon from "@/assets/svg/add.svg";
import classNames from "classnames";

function BetModal() {
    const dispatch = useAppDispatch();
    const { forecastVisible, forecastData } = useAppSelector(state => state.prediction);
    const [checked, setChecked] = useState(false);
    const [amount, setAmount] = useState(1);
    const [count, setCount] = useState(1);

    useEffect(() => {
    }, [forecastVisible]);

    const handlerRender = () => {
        return <div className="followedModal-handler">
            <div className="total">
                <div className="total-price">₹ { formatPrice(2170) }</div>
                <div
                    className="total-number"> 25 Numbers
                </div>
            </div>
            <div className="followed-button">Followed Betting</div>
        </div>;
    };
    return <FloatModal visible={ forecastVisible } onClose={ () => {
        dispatch(closeForecast());
    } } handler={ handlerRender() }>
        <div className={ cls.container }>
            <div className={ cls.detail }>
                <Image src={ forecastData.gameIcon } className={ cls.pic }/>
                <div className={ cls.detail__info }>
                    <div className={ cls.name }>{ forecastData.gameName }</div>
                    <div className={ cls.id }>{ forecastData.issueNo }</div>
                </div>
                <div className={ cls.detail__time }>
                    <div className={ cls.drawText }>Draw time</div>
                    <div className={ cls.drawTime }>{ forecastData.drawTime }</div>
                </div>
            </div>
            <div className={ cls.line }></div>
            <div className={ cls.forecast }>
                <div className={ cls.result }>
                    <div className={ cls.result_label }>forecast results:</div>
                    <div className={ cls.result_value }>
                        {
                            // renderForecastResults(forecastData)
                        }
                    </div>
                </div>
                <div className={ cls.select }>
                    <div className={ cls.select_item }>
                        <CheckedBox checked={ checked } setChecked={ setChecked }/>
                        <Reflex.Dice type={ DiceType.SUM } value={ "4" }/>
                    </div>
                    <div className={ cls.select_item }>
                        <CheckedBox checked={ checked } setChecked={ setChecked }/>
                        <Reflex.Dice type={ DiceType.SUM } value={ "Big" }/>
                    </div>
                    <div className={ cls.select_item }>
                        <CheckedBox checked={ checked } setChecked={ setChecked }/>
                        <Reflex.Dice type={ DiceType.SUM } value={ "Odd" }/>
                    </div>
                </div>
            </div>
        </div>
        <div className={ cls.bet }>
            <div className={ cls.bet_item }>
                <div className={ cls.bet_item_num }>
                    <div>Bet Amount:</div>
                    <div className={ cls.bet_item_num_input }>
                        <Button className={ cls.tab }>
                            <img src={ subIcon } alt=""/>
                        </Button>
                        <div className={ classNames(cls.tab, cls.tabValue) }>{ amount }</div>
                        <Button className={ cls.tab }>
                            <img src={ addIcon } alt=""/>
                        </Button>
                    </div>
                </div>
                <div className={ cls.bet_item_value }>
                    <Button className={ classNames(cls.bet_item_value_select, cls.bet_item_value_select_checked) }>₹10
                    </Button>
                    <Button className={ cls.bet_item_value_select }>₹100</Button>
                    <Button className={ cls.bet_item_value_select }>₹500</Button>
                    <Button className={ cls.bet_item_value_select }>₹1000</Button>
                </div>
            </div>
            <div className={ cls.line }></div>
            <div className={ cls.bet_item }>
                <div className={ cls.bet_item_num }>
                    <div>Bet Count:</div>
                    <div className={ cls.bet_item_num_input }>
                        <div className={ cls.tab }>
                            <img src={ subIcon } alt=""/>
                        </div>
                        <div className={ classNames(cls.tab, cls.tabValue) }>{ count }</div>
                        <div className={ cls.tab }>
                            <img src={ addIcon } alt=""/>
                        </div>
                    </div>
                </div>
                <div className={ cls.bet_item_value }>
                    <Button className={ classNames(cls.bet_item_value_select, cls.bet_item_value_select_checked) }>1bets</Button>
                    <Button className={ cls.bet_item_value_select }>3bets</Button>
                    <Button className={ cls.bet_item_value_select }>10bets</Button>
                    <Button className={ cls.bet_item_value_select }>25bets</Button>
                </div>
            </div>
        </div>
    </FloatModal>;
}

export default BetModal;
