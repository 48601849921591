import { Image } from "antd-mobile";
import defaultPerson from "@/assets/svg/default-person.svg";
import { useAppDispatch, useAppSelector } from "@/store";
import React from "react";
import { authLogin, toast } from "@/utils";
import { openPersonVisible } from "@/store/slice/personal";
import { ZIMRoomMemberInfo } from "@chat/zim-sdk";
import { useParams } from "react-router-dom";
import useIdentity from "@/hooks/useIdentity";

export const MemberRole = (props: { userID: string, marginLeft?: number }) => {
    const { userID, marginLeft } = props;
    const { adminList } = useAppSelector(state => state.groupSetting);
    if (adminList.includes(userID)) return <div className="role-tag role-tag-super" style={ { marginLeft } }>
        Group Admin
    </div>;
    return null;
};
export default function Member() {
    const { totalCount, memberList } = useAppSelector(state => state.groupSetting);
    const { conversationID } = useParams();
    const dispatch = useAppDispatch();
    const { identityList } = useAppSelector(state => state.common);
    const { adminList } = useAppSelector(state => state.groupSetting);
    const handleClick = (item: ZIMRoomMemberInfo) => {
        if (authLogin()) {
            const isIdentity = identityList.some(identity => identity.imUserId === item.userID);
            let adminData = adminList.find(adminItem => adminItem === item.userID);
            if (!!adminData || isIdentity) {
                toast("No access to group admins and CS", "error");
                return;
            }
            dispatch(openPersonVisible({ userId: item.userID, groupId: conversationID }));
        }
    };
    return <div className="group-member">

        <div className="group-member-value">
            <div className="group-member-value_bg"></div>
            <div className="text">{ totalCount } participants</div>
        </div>
        <div className="group-member-list">
            {
                memberList.slice(0, 200).map(item => {
                    return <div className="group-member-item" key={ item.userID } onClick={ () => handleClick(item) }>
                        <Image src={ item.userAvatarUrl } className="avatar"
                               fallback={ <Image src={ defaultPerson }></Image> }></Image>
                        <div className="name">{ item.userName }</div>
                        <MemberRole userID={ item.userID } marginLeft={ 10 }/>
                    </div>;
                })
            }
            {
                totalCount >= 200 && <div className="max-text">Maximum display of 200 members</div>
            }
        </div>
    </div>;
}
