import React from "react";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

export default function VideoClose(props: IconProps) {
    const { ...attrs } = props;
    return <span role="img" { ...attrs }>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 29" fill="none">
            <path d="M21 7.69141L7 21.6914" stroke="white" strokeWidth="2.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M7 7.69141L21 21.6914" stroke="white" strokeWidth="2.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    </span>;

}
