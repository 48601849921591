import { login, addEvent, offEvent, logout } from "@chat/zim-sdk";
import { useEffect, useState } from "react";
import { useAppSearchParams } from "@chat/shared";
import store2 from "store2";
import { initTheme } from "@/theme";
import { getImToken } from "@/apis/im";
import { toast, visitorStore } from "@/utils";
import { useAppDispatch } from "@/store";
import { setLogged, updateSelf } from "@/store/slice/user";
import { fetchIdentity, setExpiredVisible, setFull } from "@/store/slice/common";

const getVisitorState = (data: any): boolean => {
    if (data) {
        return data === "true";
    }
    return store2.get("visitor");
};
// 1.先判断是否是游客，如果是游客不走登录
// 2.不是游客，获取当前的originToken，如果存在就登录，不存在取本地的imtoken，存在就登录。不存在就报错

export function useZimInit() {
    const [errPage, setErrPage] = useState(false);
    const [mounted, setMounted] = useState(false);
    const originToken = useAppSearchParams("originToken", store2.get("originToken"));
    let deviceCode = useAppSearchParams("deviceCode", store2.get("deviceCode"));
    const packageId = useAppSearchParams("packageId", store2.get("packageId") || 5);
    let full = useAppSearchParams("full", store2.get("full") || "0"); // 1显示
    const visitorData = useAppSearchParams("isVisitor");
    const isVisitor = getVisitorState(visitorData);
    const dispatch = useAppDispatch();
    const getUserToken = async () => {
        store2.set("packageId", parseInt(packageId) || 3);
        store2.set("full", full);
        store2.set("visitor", isVisitor);
        console.log("visitor===>", isVisitor);
        if (isVisitor) {
            // 1. 游客
            store2.remove("originToken");
        } else {
            // 1.不是游客，读取origintoken
            store2.set("originToken", originToken);
        }
        // eslint-disable-next-line
        dispatch(setFull(full == 1));
        try {
            const res = await getImToken(isVisitor ? deviceCode : null);
            if (res.code === 0) {
                const result: Record<any, any> = {
                    visitor: isVisitor,
                    deviceCode,
                    imToken: res.data.token,
                    userID: res.data.userID
                };
                if (!isVisitor) {
                    result.originToken = originToken;
                }
                return result;
            } else {
                return Promise.reject(res);
            }
        } catch (err) {
            return Promise.reject(err);
        }
    };
    const init = async () => {
        initTheme();
        addEvent({
            getImToken,
            afterRefreshToken(token) {
                store2.set("imToken", token);
            },
            kickedOut: () => {
                dispatch(setExpiredVisible(true));
            }
        });
        const res = await getUserToken().catch(err => {
            toast(err.msg || "something went wrong", "error");
            setErrPage(true);
        });
        if (res) {
            if (!res.visitor) {
                // 如果当前不是游客，清除一次缓存
                if(visitorStore.getPermanentStore()?.userID !== res.userID) {
                    visitorStore.clearPermanentStore();
                }
            }
            store2.setAll(res);
            const { imToken, userID } = res;
            if (userID && imToken) {
                dispatch(fetchIdentity()).then((identRes: any) => {
                    // 获取客服，创建常驻本地数据
                    visitorStore.createPermanentStore(identRes.payload.filter((item: any) => {
                        return item.imPermanent === "1";
                    }), userID);
                    login(userID, imToken).then(userInfo => {
                        // 更新当前登录用户的信息
                        dispatch(updateSelf(userInfo));
                        // 获取公共客服列表
                        if (!isVisitor) {
                            // 非游客
                            dispatch(setLogged(true));
                        }
                        setMounted(true);
                    }).catch(err => {
                        console.error(err);
                    });
                });

            }
        }

    };
    useEffect(() => {
        init();
        return () => {
            offEvent();
            logout();
        };
    }, []);
    // 登录
    return {
        errPage,
        mounted
    };
}

