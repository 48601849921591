import { createHttp, TOKEN_TYPE, type TypeResponse } from "./http";

const http = createHttp(process.env.REACT_APP_IM_API_URL!, TOKEN_TYPE.ORIGIN);
const apis = {
    getReqParams: "/zgIm/getReqParams", // 获取imtoken [ x ]
    // 获取客服
    getCustomer: "/zgIm/customers",
    // 分页获取gif
    getGifPage: "/zgIm/getImEmoticonsPage", // [ x ]
};
type ImTokenRes = {
    userID: string;
    token: string;
}
// 获取imtoken
export const getImToken = (deviceCode?: string) => {
    const headers = deviceCode ? {
        deviceCode
    } : {};
    return http.post<any, TypeResponse<ImTokenRes>>(apis.getReqParams, null, {
        headers
    });
};
export type IdentityItem = {
    imCustomer: "1" | "2";
    imPermanent: "0" | "1";
    imUserId: string;
    imUserStatus: string;
    userAvatar: string;
    userId: number;
    userName: string;
}

export const getCustomer = () => {
    // imCustomer 可选 1支付客服 2游戏客服
    return http.get<any, TypeResponse<IdentityItem[]>>(apis.getCustomer);
};

// 获取gif图片
export const getGifPage = (params: { pageNo: number, pageSize: number }) => {
    return http.post<any, TypeResponse<any>>(apis.getGifPage, params);
}
