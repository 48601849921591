import type { CardItemItem } from "@/utils";
import { Reflex } from "@chat/component";

type QuickComprehensiveProps = {
    data: CardItemItem[]
}

function QuickComprehensive(props: QuickComprehensiveProps) {
    const { data } = props;
    return <div className="dice-game">
        {
            data.map((item, i) => {
                return <div className="dice-game-item" key={ i }>
                    <Reflex.QuickComprehensive type={ item.modeID! } value={ item.number as string }/>
                    {/*<div className="price">₹{ item.amount.toFixed(2) } </div>*/ }
                </div>;
            })
        }

    </div>;
}

export default QuickComprehensive;
