import { ZIMMessage } from "@chat/zim-sdk";
import { Fragment } from "react";

type TextProps = {
    message: ZIMMessage
};

function Text(props: TextProps) {
    const { message } = props.message;
    return <Fragment>{ message }</Fragment>;
}

export default Text;
