import "./waterfall.scss";
import type { ICardItem, IPosItem, IRenderItem, IWaterfallProps } from "./type";
import { useThrottleFn, useDebounceFn } from "ahooks";
import { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from "react";

function Waterfall(props: IWaterfallProps, ref: Ref<any>) {
    const {
        gap = 16,
        pageSize = 20,
        showLoading = true,
        columnNum,
        request,
        children,
        loadingSlot,
        onCardClick,
        requestError,
    } = props;
    const waterfallContainerRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const slotRefs = useRef<{ [key: number]: HTMLDivElement }>({});

    const columnWidth = useRef(0);
    const page = useRef(0);
    const [noFetch, setNoFetch] = useState(true);
    const screenOffset = useRef(0);
    const dataList = useRef<ICardItem[]>([]);

    const positionList = useRef<IPosItem[]>([]);
    const domDataList = useRef<IRenderItem[]>([]);
    const isLoadNextPage = useRef(false);
    const isShowToTop = useRef(false);
    const hasNextPage = useRef(true);
    const lastOffsetWidth = useRef(0);
    const [renderList, setRenderList] = useState<IRenderItem[]>([]);
    const timer = useRef<number | NodeJS.Timeout>(0);
    //初始化数据
    useEffect(() => {
        init();
    }, []);

    //监听滚动
    useEffect(() => {
        const waterfallContainerEle = waterfallContainerRef.current;
        waterfallContainerEle?.addEventListener("scroll", () => {
            handleScroll();
        });
        return () => {
            waterfallContainerEle?.removeEventListener("scroll", handleScroll);
        };
    }, []);
    //监听容器尺寸变化
    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            handleResize();
        });
        if (waterfallContainerRef.current) {
            resizeObserver.observe(waterfallContainerRef.current);
        }
        return () => {
            if (waterfallContainerRef.current) {
                resizeObserver.unobserve(waterfallContainerRef.current);
            }
        };
    }, [columnNum]);
    const init = async () => {
        screenOffset.current = waterfallContainerRef.current!.offsetHeight / 2;
        lastOffsetWidth.current = waterfallContainerRef.current!.offsetWidth;
        // 初始化宽度
        computeColumnWidth();
        // 初始化高度
        initPositionList();
        setNoFetch(false);
        computeDomData(await getList(), 0);
    };

    const { run: handleScroll } = useThrottleFn(async () => {
        if (!waterfallContainerRef.current) {
            return;
        }
        const scrollTop = waterfallContainerRef.current.scrollTop;
        isShowToTop.current = scrollTop >= window.innerHeight;
        updatePos();
        if (isLoadNextPage.current || !hasNextPage.current) {
            return false;
        }
        if (
            waterfallContainerRef.current.scrollTop + waterfallContainerRef.current.offsetHeight >=
            waterfallContainerRef.current.scrollHeight * 0.85
        ) {
            const nextList = await getList();
            const startIdx = (page.current - 1) * pageSize;
            computeDomData(nextList, startIdx);
        }
    }, {
        wait: 100
    });
    //容器尺寸变化
    const { run: handleResize } = useDebounceFn(function () {
        resizeFn();
    });
    const resizeFn = () => {
        if (!waterfallContainerRef.current) return;
        computeColumnWidth();
        lastOffsetWidth.current = waterfallContainerRef.current.offsetWidth;
        initPositionList();
        computeDomData(dataList.current, 0, true);
    };
    const initPositionList = () => {
        positionList.current = [];
        for (let i = 0; i < columnNum; i++) {
            positionList.current.push({
                columnIdx: i + 1, //第几列
                columnHeight: 0, //对应高
            });
        }
    };
    //计算每一列宽度
    const computeColumnWidth = () => {
        if (containerRef.current) {
            const allGapLength = (columnNum - 1) * gap;
            columnWidth.current = (containerRef.current.offsetWidth - allGapLength) / columnNum;
        }
    };
    const computeDomData = (list?: ICardItem[], startIdx: number = 0, isReSize = false) => {
        if (!list) return;
        const length = list.length;
        for (let i = 0; i < length; i++) {
            const item = list[i];
            const idx = i + startIdx;
            const imageHeight = (item.height * columnWidth.current) / item.width;
            const footer_height = slotRefs.current[idx]?.offsetHeight || 0; //获取底部高度
            const param: IRenderItem = {
                ...item,
                idx,
                columnIdx: 0,
                imageHeight,
                height: imageHeight,
                width: columnWidth.current,
                top: 0,
                left: 0,
                show: true,
                url: item.url || "",
                style: {
                    width: columnWidth.current + "px",
                    height: imageHeight + footer_height + "px",
                },
            };
            if (isReSize) {
                domDataList.current[i] = param;
            } else {
                domDataList.current.push(param);
            }
        }
        const showList = domDataList.current.filter((item) => item.show);
        setRenderList(showList);
        timer.current = setTimeout(() => {
            setCardPos(startIdx);
            clearTimeout(timer.current);
        }, 10);
    };
    const setCardPos = (startIdx: number) => {
        const length = domDataList.current.length;
        const end = startIdx === 0 ? length : startIdx + pageSize;
        const endIdx = end <= length ? end : length;
        for (let i = startIdx; i < endIdx; i++) {
            const domItem = domDataList.current[i];
            const { imageHeight, width, idx } = domItem;
            const param: ICardItem = {
                ...domItem,
            };
            const footer_height = slotRefs.current[idx]?.offsetHeight || 0; //获取底部高度
            const height = imageHeight + footer_height;
            positionList.current.sort((a, b) => a.columnHeight - b.columnHeight);
            const minColumn = positionList.current[0];
            const { columnIdx, columnHeight } = minColumn;
            const top = columnHeight;
            const left = (columnIdx - 1) * (columnWidth.current + gap);
            param.columnIdx = columnIdx;
            param.top = top;
            param.left = left;
            param.height = height;
            param.style = {
                width: width + "px",
                height: height + "px",
                opacity: 1,
                transform: `translate3d(${ left }px,${ top }px, 0)`,
            };
            param.show = !!checkIsRender(param);
            positionList.current[0].columnHeight += height + gap;
            domDataList.current[i] = { ...domItem, ...param };
        }
        setRenderList(domDataList.current.filter((item) => item.show));
        setContainerHeight();
    };
    const updatePos = () => {
        domDataList.current.forEach((item, index) => {
            domDataList.current[index].show = !!checkIsRender(item);
        });
        setRenderList(domDataList.current.filter((item) => item.show));
    };
    const setContainerHeight = () => {
        const loadingHeight = showLoading ? 32 : 0;
        positionList.current.sort((a, b) => a.columnHeight - b.columnHeight);
        if (containerRef.current) {
            containerRef.current.style.height =
                positionList.current[positionList.current.length - 1].columnHeight + loadingHeight + "px";
        }

    };
    const checkIsRender = (params: ICardItem) => {
        if (!waterfallContainerRef.current) {
            return;
        }
        const { top, height } = params;
        const y = top + height;
        const topLine = waterfallContainerRef.current.scrollTop - screenOffset.current;
        const bottomLine =
            waterfallContainerRef.current.scrollTop + waterfallContainerRef.current.offsetHeight + screenOffset.current;
        const overTopLine = y < topLine;
        const underBottomLine = top > bottomLine;
        return !overTopLine && !underBottomLine;
    };
    const getList = async () => {
        try {
            isLoadNextPage.current = true;
            page.current++;
            const nextList = await request(page.current, pageSize);
            hasNextPage.current = !!nextList.length && nextList.length === pageSize;
            dataList.current = page.current === 1 ? nextList : dataList.current.concat(nextList);
            isLoadNextPage.current = false;
            return nextList;
        } catch (error) {
            requestError && requestError(error);
        }
    };
    const scrollToTop = () => {
        waterfallContainerRef.current!.scrollTo({
            left: 0,
            top: 0,
            behavior: "smooth",
        });
    };
    useImperativeHandle(ref, () => ({
        scrollToTop
    }));
    return (
        <div ref={ waterfallContainerRef } className="hm_waterfall-container">
            <div ref={ containerRef } className="hm_container">
                { renderList.map((item) => {
                    return (
                        <div
                            className="hm_waterfall-item"
                            key={ item.idx }
                            data-index={ item.idx }
                            style={ item.style }
                            onClick={ () => onCardClick && onCardClick(item) }
                        >
                            <div
                                className="hm_waterfall-item-main"
                                style={ {
                                    height: item.imageHeight,
                                    backgroundImage: "url(" + item.url + ")",
                                    backgroundSize: "100% 100%",
                                } }
                            ></div>
                            <div
                                className="hm_waterfall-footer"
                                ref={ (el: HTMLDivElement) => {
                                    slotRefs.current[item.idx] = el;
                                } }
                                style={ { width: item.width + "px" } }
                            >
                                { children?.(item) }
                            </div>
                        </div>
                    );
                }) }
                { noFetch || showLoading && isLoadNextPage.current &&
                    <div className="hm_waterfall-loading">{ loadingSlot ? loadingSlot : " loading..." }</div> }
            </div>
        </div>
    );
}

export default forwardRef(Waterfall);
