import cls from "./foot.module.scss";
import { setLoginModalVisible } from "@/store/slice/common";
import { useAppDispatch } from "@/store";

function RegisterBottom() {
    const dispatch = useAppDispatch();
    const handleClick = () => {
        dispatch(setLoginModalVisible(true));
    }
    return <div className={ cls.register } onClick={handleClick}>Please login to send message</div>;
}

export default RegisterBottom;
