import { MessageStatus, MessageType, ZIMMediaMessage } from "@chat/zim-sdk";
import { ProgressCircle } from "antd-mobile";
import { VideoClose, VideoPlay } from "@/components/normalSvg";

type LoadingBallProps = {
    style: any
    data: ZIMMediaMessage & { progress?: number }
}
const LoadingBall = (props: LoadingBallProps) => {
    const { style, data } = props;
    const { progress = 0 } = data;
    const isSending = data.sentStatus === MessageStatus.Sending;
    const renderBall = () => {
        if (isSending) {
            return <div className="message-loading-ball">
                <VideoClose className="message-loading-ball__icon"></VideoClose>
            </div>;
        }
        if (data.type === MessageType.Video) {
            return <div className="message-loading-ball">
                <VideoPlay className="message-loading-ball__icon"></VideoPlay>
            </div>;
        }
        return null;
    };
    return <div style={ style } className="message-loading">
        {
            renderBall()
        }
        {
            isSending &&
            <ProgressCircle percent={ progress } style={ { "--track-color": "none", "--track-width": "4px" } }
                            className="message-loading-progress-bar"></ProgressCircle>
        }
    </div>;
};
export default LoadingBall;
