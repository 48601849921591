import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserInfo, IUserInfo, getGroupUserInfo } from "@chat/zim-sdk";
import { getAdminList, getUserStatistics } from "@/apis/room";
import { GroupMemberItem } from "@/store/slice/chat";

const NAME = "personal";
type FavoriteGameResListItem = {
    gameId: number;
    winAmount: number;
    gameName: string;
    gameUrl: string;
    gamePicUrl: string
}
type User = { likeCount: number, myLike: boolean } & IUserInfo & GroupMemberItem;
const initialState = {
    user: {} as User,
    statistics: {
        favoriteGameResList: [] as FavoriteGameResListItem[],
        totalBets: 0,
        totalWagered: 0,
        totalWins: 0,
    },
    blockVisible: false,
    cancelBlockVisible: false,
    loading: true,
    bigVisible: false,
    personVisible: false,
    userId: "",
    groupId: "",
    mutedVisible: false,
    cancelMutedVisible: false
};
export const getUserByGroupId = createAsyncThunk("personal/getUserByGroupId", async (arg, { getState }) => {
    const state = getState() as any;
    const { userId, groupId } = state.personal as any;
    const [{ data: statisticsData }, { user }, { data: adminList }] = await Promise.all([getUserStatistics({
        imUserId: userId,
        imGroupId: groupId
    }), getUserInfo(userId, true), getAdminList({ groupId })]);
    let statistics = {
        favoriteGameResList: [] as FavoriteGameResListItem[],
        totalBets: 0,
        totalWagered: 0,
        totalWins: 0,
    };
    const ifAdmin = adminList.find((item: any) => item.imUserId === userId);
    statistics.totalWagered = statisticsData.totalWagered;
    statistics.totalBets = statisticsData.totalBets;
    statistics.totalWins = statisticsData.totalWins;
    statistics.favoriteGameResList = statisticsData.favoriteGameResList;
    const userInfo = {
        likeCount: statisticsData.imUserInfoVo.likeCount,
        myLike: statisticsData.imUserInfoVo.ifLike,
        ...user,
        ...getGroupUserInfo({ imGroupOperateVos: statisticsData.imGroupOperateVos, ifAdmin } as any, userId)
    };
    return {
        user: userInfo,
        statistics
    };
});
export const personalSlice = createSlice({
    initialState,
    name: NAME,
    reducers: {
        setUserLikeCount(state, action) {
            state.user.likeCount = action.payload;
        },
        setBlockVisible(state, action) {
            state.blockVisible = action.payload;
        },
        setCancelBlockVisible(state, action) {
            state.cancelBlockVisible = action.payload;
        },
        setUserMyLike(state, action) {
            state.user.myLike = action.payload;
        },
        setBigVisible(state, action) {
            state.bigVisible = action.payload;
        },
        closePersonVisible(state) {
            state.personVisible = false;
        },
        openPersonVisible(state, action) {
            const { userId, groupId } = action.payload;
            state.personVisible = true;
            state.userId = userId;
            state.groupId = groupId;
        },
        setMutedVisible(state, action) {
            state.mutedVisible = action.payload;
        },
        setCancelMutedVisible(state, action) {
            state.cancelMutedVisible = action.payload;
        },
        reset: () => initialState
    },
    extraReducers(builder) {
        builder.addCase(getUserByGroupId.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getUserByGroupId.fulfilled, (state, action) => {
            const { user, statistics } = action.payload;
            state.user = user;
            state.statistics = statistics;
            state.loading = false;
        });
        builder.addCase(getUserByGroupId.rejected, state => {
            // toast("error", "error");
            state.loading = false;
        });
    }
});

export const {
    setUserLikeCount,
    setUserMyLike,
    setBigVisible,
    closePersonVisible,
    openPersonVisible,
    setMutedVisible,
    setCancelMutedVisible,
    setBlockVisible,
    setCancelBlockVisible,
    reset
} = personalSlice.actions;

export default personalSlice.reducer;
