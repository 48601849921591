import { Checkbox } from "antd-mobile";
import checkBoxIcon from "@/assets/svg/checkBox.svg";
import { getVmSize } from "@chat/shared";

type CheckBoxProps = {
    checked: boolean;
    setChecked: (checked: boolean) => void;
}

function CheckedBox(props: CheckBoxProps) {
    const { checked, setChecked } = props;
    return <Checkbox
        checked={ checked }
        style={ { width: getVmSize(16), height: getVmSize(16) } }
        onChange={ setChecked }
        icon={ checked =>
            checked ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                    <rect x="0.5" width="16" height="16" rx="8" fill="#1DAB61"/>
                    <path d="M5.29883 8.24654L7.53883 10.4004L11.6988 6.40039" stroke="#F9F9FB" strokeWidth="2"
                          strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            ) : (
                <div style={ {
                    borderRadius: "50%",
                    width: getVmSize(16),
                    height: getVmSize(16),
                    border: `${ getVmSize(2) } solid #e5e5e5`
                } }></div>
            )
        }
    />;
}

export default CheckedBox;
