import cls from "./foot.module.scss";
import { formatPrice } from "@chat/shared";
import { useAppSelector } from "@/store";
import useRecharge from "@/pages/Chat/feature/hooks/useRecharge";
import { useParams } from "react-router-dom";
import useIdentity from "@/hooks/useIdentity";
import { useEffect } from "react";
import { emitter } from "@/utils";

function QuickMessage() {
    const { rechargeBalanceList } = useAppSelector(state => state.chat);
    const { conversationID = "" } = useParams();
    const { isIdentity } = useIdentity(conversationID, ["1"]);
    const { toRecharge } = useRecharge(conversationID);
    useEffect(() => {
        if (isIdentity) {
            setTimeout(() => {
                emitter.emit("CHAT_LIST_SCROLL_TO_BOTTOM", { behavior: "auto" });
            }, 50)
        }
    }, [isIdentity]);
    if (!isIdentity) return null;
    return <div className={ cls.quickMessage }>
        {
            rechargeBalanceList.map(item => {
                return <div className={ cls.quickMessage__item } key={ item.id }>
                    <div className={ cls.quickMessage__item__text }
                         onClick={ () => toRecharge(item.balance + "") }>+{ formatPrice(item.balance) }</div>
                </div>;
            })
        }
    </div>;
}

export default QuickMessage;
