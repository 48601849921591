import { MessageReceiptStatus, ZIMVideoMessage } from "@chat/zim-sdk";
import { Video as VideoIcon } from "@/components/themeSvg";
import { formattedTime } from "@chat/shared";
import cls from "./lasetMessageStyle.module.scss"

type AudioProps = {
    message: ZIMVideoMessage
};

export default function Video(props: AudioProps) {
    const { receiptStatus, videoDuration } = props.message;
    return <div className={cls.container}>
        <VideoIcon color={ receiptStatus === MessageReceiptStatus.Done ? "#20C164" : "#84969A" } className="text-inner-image"></VideoIcon>
        Video
        <span>{ formattedTime(videoDuration * 1000).join(":") }</span>
    </div>;
}
