import React, { memo } from "react";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function CloseNavbar(props: IconProps) {
    const { color } = props;
    return <span role="img" { ...props } >
    <svg color={ color } viewBox="0 0 25 25" fill="none">
        <path d="M16.8969 7.2373L7.29688 16.8373" stroke="currentColor" strokeWidth="2.4" strokeLinecap="round"
              strokeLinejoin="round"/>
        <path d="M7.29688 7.2373L16.8969 16.8373" stroke="currentColor" strokeWidth="2.4" strokeLinecap="round"
              strokeLinejoin="round"/>
    </svg>
    </span>;


}

export default memo(CloseNavbar);
