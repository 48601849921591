import React, { useCallback, useEffect, useRef } from 'react';
import InputExpression from "./InputExpression";
import {
    setChatMessage,
    setShowFace,
} from "@/store/slice/chat";
import { useAppDispatch, useAppSelector } from "@/store";
import { authLogin, emitter, isDefaultUser, isVisitorUser, toast } from "@/utils";
import { isChinese } from "@chat/shared";
import cls from "./foot.module.scss";
import RegisterBottom from "@/pages/Chat/feature/InputFoot/RegisterBottom";
import InputBottom from "@/pages/Chat/feature/InputFoot/InputBottom";
// import DismissedBottom from "@/pages/Chat/feature/InputFoot/DismissedBottom";
import MutedBottom from "@/pages/Chat/feature/InputFoot/MutedBottom";
import QuickMessage from "@/pages/Chat/feature/InputFoot/QuickMessage";
import {
    ConversationType,
    createTextMessage,
    GroupMemberRole,
} from "@chat/zim-sdk";
import { createImageMessage } from "@chat/zim-sdk";
import useSendMessage from "@/pages/Chat/feature/hooks/useSendMessage";
import { useParams } from "react-router-dom";

const InputFoot = () => {
    const dispatch = useAppDispatch();
    const { sendMessage } = useSendMessage();
    const { conversationType = "", conversationID = "" } = useParams();
    const {
        quoteMessage,
        selfInfo,
        groupInfo,
        showFace,
        chatMessage
    } = useAppSelector(state => state.chat);
    const inputRef = useRef<any>();
    const textAreaRef = useRef<any>();
    const focusInput = useCallback(() => {
        textAreaRef.current?.focus();
    }, []);

    const chooseItem = async (type: "image" | "emoji", value: string) => {
        if (type === "emoji") {
            dispatch(setChatMessage(chatMessage + value));
        }
        if (type === "image") {
            // 创建一个文件对象
            const message = await createImageMessage({
                fileDownloadUrl: value,
                largeImageDownloadUrl: value,
                thumbnailDownloadUrl: value
            });
            // 发送信息
            sendMessage({
                conversationID: conversationID,
                message
            });
        }
    };
    // 发送一条信息
    const handleSendMessage = async () => {
        if (+conversationType !== ConversationType.Peer) {
            if (!authLogin() || isDefaultUser()) return;
        }
        if (isChinese(chatMessage)) {
            toast("The use of Chinese characters is prohibited in the chat room", "error");
            return;
        }
        // 根据不同的类型 产出不同的信息
        // 引用信息， 引用
        let message = createTextMessage(chatMessage.trim());
        sendMessage({
            message,
            originalMessage: quoteMessage,
            conversationID: conversationID
        });
    };
    useEffect(() => {
        emitter.on("INPUT_FOCUS", focusInput);
        return () => {
            emitter.off("INPUT_FOCUS", focusInput);
        };
    }, []);

    const renderContainer = () => {
        const isAdmin = selfInfo.memberRole === GroupMemberRole.Admin;
        // 如果是游客，但是单聊，不受影响
        if (+conversationType === ConversationType.Peer) return <InputBottom showMenus ref={ inputRef }
                                                                             handleSend={ handleSendMessage }/>;

        if (isVisitorUser()) return <RegisterBottom/>;
        if (selfInfo.userID && selfInfo.muteExpiredTime !== 0) return <MutedBottom/>;
        if (!groupInfo) return null;
        if (+conversationType === ConversationType.Room) {
            // 频道
            if (groupInfo?.groupType === 3) {
                // 管理员才显示
                if (isAdmin) return <InputBottom ref={ inputRef } handleSend={ handleSendMessage } showMenus/>
                return <MutedBottom always/>;
            }
        }
        return <InputBottom ref={ inputRef } handleSend={ handleSendMessage } showMenus={ isAdmin }/>;
        // todo 群解散展示
        // if (groupInfo?.status === GroupStatus.Dismissed) return <DismissedBottom/>;
    };
    return <div className={ cls.container }>
        <QuickMessage/>
        <div style={ { padding: 4 } }>
            {
                renderContainer()
            }
        </div>
        <InputExpression
            visible={ showFace }
            setVisible={ (val) => dispatch(setShowFace(val)) }
            chooseItem={ chooseItem }
            handleDelete={ () => inputRef.current?.deleteChangeMessage() }
        ></InputExpression>
    </div>;
    // 未登录状态
};

export default InputFoot;
