import React, { useMemo, useRef } from 'react';
import useNavigate from "@/hooks/useNavigate";
import { Image } from "antd-mobile";
import { useLongPress } from "ahooks";
import defaultChat from "@/assets/svg/defalt-chat.svg";
// import msgRead from "@/assets/svg/msg-read.svg";
// import msgUnRead from "@/assets/svg/msg-unread.svg";
import { formatConversionTime, replaceOssImage } from "@chat/shared";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "@/store";
import { addSelectItem, CustomConversationItem, showPassword, } from "@/store/slice/home";
import { toast } from "@/utils";
import { IdentityBaw, ReadStatus, SelectCircle } from "@/components/themeSvg";
import {
    formatLastMessage,
    getConversationType,
    MessageReceiptStatus,
    NotificationStatus,
    ConversationType,
    getGroupUserInfo
} from "@chat/zim-sdk";
import LastMessage from "@/pages/Home/features/LastMessage";
import { getUserGroupInfo } from "@/apis/room";
import store2 from "store2";
import { showDismissVisible } from "@/store/slice/common";
import useIdentity from "@/hooks/useIdentity";

type MessageItemProps = {
    data: CustomConversationItem
}

function MessageItem(props: MessageItemProps) {
    const { data } = props;

    const { isIdentity } = useIdentity(data.conversationID);
    const handlerList = useMemo(() => {
        const isTop = data.type === ConversationType.Peer || (data.type === ConversationType.Room && data.groupType === 3);
        return [
            {
                name: "muted",
                show: data.notificationStatus === NotificationStatus.DoNotDisturb,
                icon: require("@/assets/svg/muted.svg").default
            },
            {
                name: "top",
                show: isTop,
                icon: require("@/assets/svg/top-group-list.svg").default
            },
            {
                name: "clock",
                show: data.isPassword && data.isNotInGroup,
                icon: require("@/assets/svg/clock.svg").default
            },
            {
                name: "unclock",
                show: data.isPassword && !data.isNotInGroup,
                icon: require("@/assets/svg/unlock.svg").default
            }

        ];
    }, [data]);
    const navigate = useNavigate();
    const messageRef = useRef<HTMLDivElement>(null);
    const { setting, selectList } = useAppSelector(state => state.home);
    const dispatch = useAppDispatch();
    const handleClick = () => {
        if (!status.current) {
            return;
        }
        if (setting) {
            dispatch(addSelectItem(data));
        } else {
            handleChat();
        }
    };
    const status = useRef(false);
    formatLastMessage(data);
    useLongPress(() => {
        // 长按关闭
        // if (!setting) {
        //     dispatch(addSelectItem(data));
        //     dispatch(showSetting());
        // }
    }, messageRef, {
        delay: 500,
        moveThreshold: {
            x: 20,
            y: 10
        },
        onClick() {
            status.current = true;
        },
        onLongPressEnd: () => {
            status.current = false;
        }
        // onClick: () => {
        //     if (setting) {
        //         dispatch(addSelectItem(data));
        //     } else {
        //         handleChat();
        //     }
        // }
    });
    const handleChat = () => {
        // 加入之前需要判断是否是登录状态，否则去登录
        // 判断当前列表内容，如果是房间走判断，如果不是直接加入
        if (getConversationType(data.conversationID) === ConversationType.Peer) {
            // if (!authLogin()) return;
            navigate(`/chat/${ data.conversationID }/${ data.type }`);
        } else {
            // 去查询房间信息
            getUserGroupInfo({ groupId: data.conversationID, imUserId: store2.get("userID") }).then(res => {
                const { data: groupData, code } = res;
                if (code !== 0) {
                    toast(res.message || res.msg, "error");
                    return;
                }
                const user = getGroupUserInfo(groupData, store2.get("userID"));
                if (user.block) {
                    toast("You have been blocked", "error");
                    return;
                }
                // 找不到房间信息，说明已经解散了
                if (!groupData.imGroupVo.groupId) {
                    dispatch(showDismissVisible());
                    return;
                }
                // 如果已经加入过，那么不需要密码
                if (groupData.imGroupVo.groupType === 1 || !data.isPassword || res.data.fillPass) {
                    navigate(`/chat/${ data.conversationID }/${ data.type }`);
                } else {
                    // 如果未加入过，需要输入密码加入
                    dispatch(showPassword({ groupID: data.conversationID }));
                }
            });
        }
    };
    const isSelect = () => {
        const index = selectList.findIndex(item => item.conversationID === data.conversationID);
        return index > -1;
    };
    const renderNickname = () => {
        return <div className="title">
            <h3>{ data.conversationName } </h3>
            {
                (isIdentity || data.groupType === 3) ? <IdentityBaw className="home-identity__icon"/> : null
            }
        </div>;
    };
    const renderFaceURL = () => {
        return replaceOssImage(data.conversationAvatarUrl) + "?x-oss-process=image/resize,m_lfit,w_92";
    };
    return (
        <div className={ classNames("message-item-box", { select: isSelect() }) } onClick={ handleClick }>
            <div className="message-item" ref={ messageRef }>
                <div className="message-item-icon">
                    <Image className="image"
                           src={ renderFaceURL() }
                           fallback={ <Image src={ defaultChat }></Image> }
                           lazy
                    />
                    {
                        isSelect() && <SelectCircle className="select-icon"/>
                    }

                </div>
                <div className="message-item-content">
                    <div className="message-item-content-top">
                        {
                            renderNickname()
                        }
                        <div className={ classNames("time", { "time-read": (data.unreadMessageCount || 0) === 0 }) }>
                            {
                                formatConversionTime(data.lastMessage?.timestamp!, true)
                            }
                        </div>
                    </div>
                    <div className="message-item-content-bottom">

                        <div className="text">
                            <span className="text-content">
                                        <LastMessage message={ data.lastMessage! }/>
                                    </span>
                            {
                                data.lastMessage &&
                                <ReadStatus read={ data.lastMessage?.receiptStatus === MessageReceiptStatus.Done }
                                            className="text-icon"/>
                            }
                        </div>
                        <div className="handler">
                            {
                                handlerList.filter(item => item.show).map(item => {
                                    return <Image key={ item.name } className="handler-icon" src={ item.icon }></Image>;
                                })
                            }
                            {
                                data.unreadMessageCount && data.notificationStatus !== NotificationStatus.DoNotDisturb ?
                                    <div className="count">{ data.unreadMessageCount > 99 ? "99+" : data.unreadMessageCount }</div> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MessageItem;
