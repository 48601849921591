import React, { useEffect, useRef, useState } from 'react';
import { FloatingPanelRef, Grid, Image } from "antd-mobile";
import type { FloatingPanelProps } from "antd-mobile";
import emojiIcon from "@/components/themeSvg/Emoj";
import gifIcon from "@/components/themeSvg/Gif";
import deleteIcon from "@/assets/svg/delete.svg";
import { EmojiDataList } from "@chat/shared";
import classNames from "classnames";
import GifBox from "@/pages/Chat/feature/InputFoot/GifBox";
import { FloatingPanel } from "@/components/FloatModal/floating-panel";

export type ExpressType = "emoji" | "image"
type InputExpressionProps = {
    chooseItem: (type: ExpressType, value: string) => void;
    handleDelete: () => void;
    visible: boolean;
    setVisible: (...args: any[]) => void
} & Omit<FloatingPanelProps, "anchors" | "children">

const config = {
    headerHeight: 24,
    titleHeight: 28,
    handlerHeight: 0,
    anchors: [0, 250, window.innerHeight * 0.8 - 30]
};
const InputExpression = (props: InputExpressionProps) => {
    const floatRef = useRef<FloatingPanelRef>(null);
    const { chooseItem, handleDelete, visible, setVisible } = props;
    const [expressType, setExpressType] = useState<ExpressType>("emoji");
    const switchList = [
        {
            title: "emoji",
            icon: emojiIcon,
        },
        {
            title: "image",
            icon: gifIcon,
        },
    ];
    const EmojiBox = () => {
        return (
            <div className="chat-expression-emoji">
                <Grid columns={ 7 } gap={ [24, 16] }>
                    {
                        EmojiDataList.map(item => {
                            return <Grid.Item key={ item.id }>
                                <div className="emoji-item"
                                     onClick={ () => chooseItem("emoji", item.context) }>
                                    {
                                        item.context
                                    }
                                </div>
                            </Grid.Item>;
                        })
                    }

                </Grid>

            </div>
        );
    };
    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                floatRef.current?.setHeight(config.anchors[1], { immediate: false });
                // setContentHeight(getContentHeight(config.anchors[1]));
            }, 30);
        } else {
            floatRef.current?.setHeight(0);
        }
    }, [visible]);
    return (
        <FloatingPanel
            anchors={ config.anchors }
            handleDraggingOfContent={ true }
            onHeightChange={ (height, animating) => {
                if (height === 0 && !animating) {
                    setVisible(false);
                }
            } }
            ref={ floatRef }
            customHeader={
                <div className="chat-expression-handler">
                    <div></div>
                    <div className="chat-expression-switch">
                        {
                            switchList.map(item => {
                                const isActive = expressType === item.title;
                                return <div
                                    className={ classNames("switch-item", { active: isActive }) }
                                    key={ item.title } onClick={ () => setExpressType(item.title as any) }>
                                    <item.icon className="switch-item-icon"
                                               theme={ isActive ? '--whats-color-t2' : '--whats-color-t3' }></item.icon>
                                </div>;
                            })
                        }
                    </div>
                    <Image src={ deleteIcon } className="chat-expression-delete" onClick={ handleDelete }></Image>
                </div>
            }
            className="chat-expression"
        >
            <div className="content">
                {
                    expressType === "emoji" ? <EmojiBox/> : <GifBox chooseItem={ chooseItem }/>
                }
            </div>
        </FloatingPanel>
    );
};

export default InputExpression;
